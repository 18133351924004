<script setup lang="ts">
import { withLeadingSlash } from 'ufo'
import type { Collections } from '@nuxt/content'

const props = defineProps<{
  restrict?: string
}>()

const route = useRoute()
const { locale, t } = useI18n()

const path = computed(() => withLeadingSlash(String(route.params.slug).replaceAll(',', '/')))

const { data } = await useAsyncData(
  'content:next-page:' + route.path,
  async () => {
    const collection = getContentCollection('all', locale.value).name as keyof Collections
    if (!props.restrict) {
      return queryCollectionItemSurroundings(collection, path.value)
    }
    return queryCollectionItemSurroundings(collection, path.value).where(
      'path',
      'LIKE',
      `%${props.restrict}%`,
    )
  },
  { watch: [locale] },
)

const prevTitle = computed(() => t(`previous-page`).toUpperCase())
const nextTitle = computed(() => t(`next-page`).toUpperCase())
</script>

<template>
  <div class="container" :class="{ 'container-right': !data?.[0] }">
    <NuxtLink v-if="data?.[0]" :to="localeLink(data[0].path)" class="link">
      <VTooltip activator="parent" location="bottom" :text="data[0].title" />
      <VIcon icon="mdi:arrow-left-bold-circle" size="1.6em"></VIcon>
      <div class="text" style="text-align: left">{{ prevTitle }}</div>
    </NuxtLink>
    <NuxtLink v-if="data?.[1]" :to="localeLink(data[1].path)" class="link">
      <VTooltip activator="parent" location="bottom" :text="data[1].title" />
      <div class="text" style="text-align: right">{{ nextTitle }}</div>
      <VIcon icon="mdi:arrow-right-bold-circle" size="1.6em"></VIcon>
    </NuxtLink>
  </div>
</template>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4em;
  margin-bottom: 0;
}
.container-right {
  justify-content: end;
}
.link {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text {
  /* font-size: 0.9em; */
  font-weight: 600;
  padding: 0 0.5em 0 0.5em;
}
</style>
